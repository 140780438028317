import {
  Button,
  Dropdown,
  Menu,
  Modal,
  Popconfirm,
  Popover,
  Select,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Pagination from "../../../components/pagination";
import ActionLog from "./lib/action-log";
import BidManagement from "../rule-management-hold/modals/BidManagement";
import BudgetManagement from "./lib/budget-management";
import CampaignsView from "./lib/campaigns-view";
import SearchTermManagement from "./lib/search-term-management";
// import Wrapper from "./style";
import { TableLoading } from "../../../components/table-animation";
import { DefaultPerPage } from "../../../config";
import NoData from "../../../components/no-data";
import Wrapper, { ToggleWrapper } from "./style";
import dayjs from "dayjs";
import { GlobalContext } from "../../../commonContext";
import moment from "moment";

export default function (props) {
  const {
    GetRuleManagementListAction,
    fakeActionRuleManagement,

    DeleteRuleAction,

    CampaignsAnalyticsAllTableDataAction,

    GetRuleManagementDropdownListAction,
    fakeActionCampaignsAnalytics,
    UpdateSubRuleStatusAction,

    GetMarketplaceListAction,
    fakeActionMarketplace,

    UpdateRuleStatusAction,
  } = props;
  const contextVar = useContext(GlobalContext);

  const [actionLogView, setActionLogView] = useState(false);
  const [campaignView, setCampaignView] = useState(false);

  const [bidManagementView, setBidManagementView] = useState(false);
  const [searchTermManagementView, setSearchTermManagementView] =
    useState(false);
  const [budgetManagementView, setBudgetManagementView] = useState(false);

  const [isCloneStatus, setIsCloneStatus] = useState(false);
  const [isUpdateStatus, setIsUpdateStatus] = useState(false);

  const GetRuleManagementListRes = useSelector(
    (state) => state.RuleManagement.GetRuleManagementListResponse || {}
  );
  const UpdateRuleResponse = useSelector(
    (state) => state.RuleManagement.UpdateRuleResponse || {}
  );
  const AllCompaignsDataResponse = useSelector(
    (state) => state.CampaignsAnalytics.AllCompaignsDataResponse || {}
  );
  const GetRuleManagementDropdownResponse = useSelector(
    (state) => state.RuleManagement.GetRuleManagementDropdownResponse || {}
  );

  const DeleteRuleResponse = useSelector(
    (state) => state.RuleManagement.DeleteRuleResponse || {}
  );
  const GetMarketplaceListResponse = useSelector(
    (state) => state.MarketplaceCredential.GetMarketplaceListResponse || {}
  );
  const UpdateRuleStatusResponse = useSelector(
    (state) => state.RuleManagement.UpdateRuleStatusResponse || {}
  );
  const UpdateSubRuleStatusResponse = useSelector(
    (state) => state.RuleManagement.UpdateSubRuleStatusResponse || {}
  );

  const [allCampaignList, setAllCampaignList] = useState([]);
  const [allCampaignListLoading, setAllCampaignListLoading] = useState(true);

  const [ruleList, setRuleList] = useState([]);
  const [ruleListLoading, setRuleListLoading] = useState(true);

  const [selectedCamp, setSelectedCamp] = useState([]);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [dropdownList, setDropdownList] = useState({});
  const [dropdownLoading, setDropdownLoading] = useState(true);

  const [createANewCampaigns, setCreateANewCampaigns] = useState(false);

  const [filters, setFilters] = useState({
    rule_name: null,
    rule_type: null,
    rule_status: null,
  });
  const [selectedRow, setSelectedRow] = useState({});

  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [selectedMarketplace, setSelectedMarketplace] = useState(null);

  const [selectedActionLog, setSelectedActionLog] = useState({});
  const SIGN = () => {
    if (!localStorage.getItem("signs")) return "";
    try {
      return (
        JSON.parse(localStorage.getItem("signs"))?.[selectedMarketplace] || ""
      );
    } catch (error) {
      return "$";
    }
  };

  useEffect(() => {
    if (UpdateSubRuleStatusResponse?.status === true) {
      message.destroy();
      message.success(UpdateSubRuleStatusResponse?.message);

      setRuleList((prev) => {
        return prev.map((item) => {
          return {
            ...item,
            sub_rule_list: item?.sub_rule_list?.map((d) => {
              console.log(d, "d");
              if (
                parseInt(d?.id) ===
                parseInt(UpdateSubRuleStatusResponse?.data?.id)
              ) {
                return {
                  ...d,
                  rule_status: UpdateSubRuleStatusResponse?.data?.rule_status,
                };
              }
              return { ...d };
            }),
          };
        });
      });
      setSelectedRow({});
      setIsUpdateStatus(false);

      setBudgetManagementView(false);

      setSearchTermManagementView(false);

      setBidManagementView(false);
      fakeActionRuleManagement("UpdateSubRuleStatusResponse");
    } else if (UpdateSubRuleStatusResponse?.status === false) {
      message.destroy();
      message.error(UpdateSubRuleStatusResponse?.message);
      fakeActionRuleManagement("UpdateSubRuleStatusResponse");
    }
  }, [UpdateSubRuleStatusResponse]);
  useEffect(() => {
    if (UpdateRuleStatusResponse?.status === true) {
      message.destroy();
      message.success(UpdateRuleStatusResponse?.message);

      setRuleList((prev) => {
        return prev.map((item) => {
          if (item.id === UpdateRuleStatusResponse.data.id) {
            return {
              ...item,
              ...UpdateRuleStatusResponse.data,
            };
          }
          return item;
        });
      });
      setSelectedRow({});
      setIsUpdateStatus(false);

      setBudgetManagementView(false);

      setSearchTermManagementView(false);

      setBidManagementView(false);
      fakeActionRuleManagement("UpdateRuleStatusResponse");
    } else if (UpdateRuleStatusResponse?.status === false) {
      message.destroy();
      message.error(UpdateRuleStatusResponse?.message);
      fakeActionRuleManagement("UpdateRuleStatusResponse");
    }
  }, [UpdateRuleStatusResponse]);
  useEffect(() => {
    if (UpdateRuleResponse?.status === true) {
      message.destroy();
      message.success(UpdateRuleResponse?.message);

      setRuleList((prev) => {
        return prev.map((item) => {
          if (parseInt(item.id) === parseInt(UpdateRuleResponse.data.id)) {
            return {
              ...UpdateRuleResponse.data,
              budget_date_range: JSON.parse(
                UpdateRuleResponse?.data?.budget_date_range
              ),
              campaign_list: JSON.parse(
                UpdateRuleResponse?.data?.campaign_list
              ),
              criteria: JSON.parse(UpdateRuleResponse?.data?.criteria),
              recurrence: JSON.parse(UpdateRuleResponse?.data?.recurrence),
              name: UpdateRuleResponse?.data?.rule_name,
              sub_rule_list: [{ ...UpdateRuleResponse.data }],
            };
          }
          return { ...item };
        });
      });
      setSelectedRow({});
      setIsUpdateStatus(false);

      setBudgetManagementView(false);

      setSearchTermManagementView(false);

      setBidManagementView(false);
      fakeActionRuleManagement("UpdateRuleResponse");
    } else if (UpdateRuleResponse?.status === false) {
      message.destroy();
      message.error(UpdateRuleResponse?.message);
      fakeActionRuleManagement("UpdateRuleResponse");
    }
  }, [UpdateRuleResponse]);

  useEffect(() => {
    if (DeleteRuleResponse?.status === true) {
      message.destroy();
      message.success(DeleteRuleResponse?.message || "-");
      setRuleList((prev) =>
        prev?.filter(
          (d) => parseInt(d?.id) !== parseInt(DeleteRuleResponse?.data?.id)
        )
      );
      GetRuleManagementListAction({
        page: page,
        perPage: pageSize,
        ...filters,
        marketplace_id: selectedMarketplace,
      });
      fakeActionRuleManagement("DeleteRuleResponse");
    } else if (DeleteRuleResponse?.status === false) {
      message.destroy();
      message.error(DeleteRuleResponse?.message || "-");
      fakeActionRuleManagement("DeleteRuleResponse");
    }
  }, [DeleteRuleResponse]);

  useEffect(() => {
    if (GetMarketplaceListResponse?.status === true) {
      if (GetMarketplaceListResponse?.selected_marketplaces?.length > 0) {
        const id =
          GetMarketplaceListResponse?.selected_marketplaces?.filter(
            (d) => d?.id === "A21TJRUUN4KGV"
          )?.[0]?.id ||
          GetMarketplaceListResponse?.selected_marketplaces?.[0]?.id;
        setSelectedMarketplace(id);
        setRuleListLoading(true);
        setRuleList([]);
        GetRuleManagementListAction({
          page: page,
          perPage: pageSize,
          ...filters,
          marketplace_id: id,
        });
        setAllCampaignListLoading(true);
        setAllCampaignList([]);
        CampaignsAnalyticsAllTableDataAction({ marketplace_id: id });
      } else {
        setRuleListLoading(true);
        setRuleList([]);
        GetRuleManagementListAction({
          page: page,
          perPage: pageSize,
          ...filters,
          marketplace_id: selectedMarketplace,
        });
        setAllCampaignListLoading(true);
        setAllCampaignList([]);
        CampaignsAnalyticsAllTableDataAction({
          marketplace_id: selectedMarketplace,
        });
      }
      setMarketplaceList(GetMarketplaceListResponse?.selected_marketplaces);
      setMarketplaceLoading(false);
      fakeActionMarketplace("GetMarketplaceListResponse");
    } else if (GetMarketplaceListResponse?.status === false) {
      setMarketplaceLoading(false);
      setMarketplaceList([]);
      setSelectedMarketplace(null);
      fakeActionMarketplace("GetMarketplaceListResponse");
    }
  }, [GetMarketplaceListResponse]);

  useEffect(() => {
    if (GetRuleManagementDropdownResponse?.status === true) {
      setDropdownList(GetRuleManagementDropdownResponse?.data);
      setDropdownLoading(false);
      fakeActionRuleManagement("GetRuleManagementDropdownResponse");
    } else if (GetRuleManagementDropdownResponse?.status === false) {
      setDropdownList({});
      setDropdownLoading(false);
      fakeActionRuleManagement("GetRuleManagementDropdownResponse");
    }
  }, [GetRuleManagementDropdownResponse]);

  useEffect(() => {
    if (GetRuleManagementListRes?.status === true) {
      setRuleList(
        GetRuleManagementListRes?.data?.records?.map((d) => {
          if (d?.rule_type === "budget") {
            if (d?.sub_rule_list?.length === 0) return {};
            const {
              rule_name,
              budget_date_range,
              campaign_list,
              criteria,
              recurrence,
              ...rest
            } = d?.sub_rule_list?.[0];
            return {
              name: rule_name,
              ruleId: d?.id,
              budget_date_range: JSON.parse(budget_date_range),
              campaign_list: JSON.parse(campaign_list),
              criteria: JSON.parse(criteria),
              recurrence: JSON.parse(recurrence),
              ...rest,
              sub_rule_list: d?.sub_rule_list,
            };
          }
          return { ...d };
        }) || []
      );
      setTotalPage(GetRuleManagementListRes?.data.pagination.totalCount);
      setRuleListLoading(false);
      fakeActionRuleManagement("GetRuleManagementListResponse");
    } else if (GetRuleManagementListRes?.status === false) {
      setRuleListLoading(false);
      setRuleList([]);
      fakeActionRuleManagement("GetRuleManagementListResponse");
    }
  }, [GetRuleManagementListRes]);

  useEffect(() => {
    setDropdownLoading(true);
    GetRuleManagementDropdownListAction();

    setMarketplaceLoading(true);
    setMarketplaceList([]);
    GetMarketplaceListAction();
    return () => {};
  }, []);

  useEffect(() => {
    if (AllCompaignsDataResponse?.status === true) {
      setAllCampaignList(
        AllCompaignsDataResponse?.data?.data?.table_data || []
      );
      setAllCampaignListLoading(false);
      fakeActionCampaignsAnalytics("AllCompaignsDataResponse");
    } else if (AllCompaignsDataResponse?.status === false) {
      setAllCampaignList([]);
      setAllCampaignListLoading(false);
      fakeActionCampaignsAnalytics("AllCompaignsDataResponse");
    }
  }, [AllCompaignsDataResponse]);

  const onPageNo = (e) => {
    setRuleListLoading(true);
    setRuleList([]);
    GetRuleManagementListAction({
      page: e,
      perPage: pageSize,
      ...filters,
      marketplace_id: selectedMarketplace,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setRuleList([]);
    setPageSize(e);
    setRuleListLoading(true);
    GetRuleManagementListAction({
      page: 1,
      perPage: e,
      ...filters,
      marketplace_id: selectedMarketplace,
    });
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <a onClick={() => setBidManagementView(true)}>Bid Management</a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={() => setSearchTermManagementView(true)}>
          Search Term Management
        </a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={() => setBudgetManagementView(true)}>Budget Management</a>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "#",
      width: 80,
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Rule Name",
      width: 150,
      render: (text) => {
        return (
          <span className=" fw-bolder text-dark">{text?.name || "-"}</span>
        );
      },
    },
    {
      title: "Rule Type",
      width: 120,
      render: (text) => (
        <div className="d-flex align-items-center">
          <Tag
            color={`${
              text?.rule_type === "bid"
                ? "blue"
                : text?.rule_type === "Search_term"
                ? "magenta"
                : "cyan"
            }`}
            style={{ textTransform: "capitalize" }}
          >
            {text?.rule_type?.split("_")?.join(" ") || "-"}
          </Tag>
        </div>
      ),
    },

    {
      title: "Campaigns",
      width: 180,
      render: (text) => {
        const campaign_list =
          typeof text?.campaign_list === "object"
            ? text?.campaign_list
            : JSON.parse(text?.campaign_list);
        // console.log(campaign_list?.[0], "texttexttext");

        if (text?.rule_type === "budget") {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="two-line">
                {typeof text?.campaign_list === "object"
                  ? Object.values(text?.campaign_list?.[0]) || "-"
                  : "-"}
              </div>
              <span
                className="ms-2 "
                onClick={() => {
                  setSelectedCamp(
                    typeof text?.campaign_list === "object"
                      ? text?.campaign_list || []
                      : []
                  );
                  setCampaignView(true);
                }}
              >
                <i className="ki-outline ki-eye fs-3 text-dark" />
              </span>
            </div>
          );
        }
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="two-line">
              {Object.values(campaign_list?.[0]) || "-"}
            </div>
            <span
              className="ms-2 "
              onClick={() => {
                setSelectedCamp(campaign_list);
                setCampaignView(true);
              }}
            >
              <i className="ki-outline ki-eye fs-3 text-dark" />
            </span>
          </div>
        );
      },
    },
    {
      title: "Status",
      width: 100,
      render: (text) => {
        if (
          text?.rule_type === "Search Term Management" ||
          text?.rule_type === "Bid Management" ||
          text?.rule_type === "bid" ||
          text?.rule_type === "search_term"
        ) {
          return;
        }
        return (
          <Popconfirm
            placement="left"
            title={`Are you sure you want to ${
              text?.rule_status === "ACTIVE" ? "disable" : "enable"
            } rule?`}
            onConfirm={() => {
              message.destroy();
              message.loading("Updating...", 0);

              UpdateRuleStatusAction(text?.ruleId, {
                rule_status:
                  text?.rule_status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
              });
              setRuleList((prev) => {
                return prev.map((item) => {
                  if (item.id === text?.id) {
                    return {
                      ...item,
                      rule_status:
                        text?.rule_status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
                    };
                  }
                  return item;
                });
              });
            }}
            okText="Yes"
            cancelText="No"
          >
            <ToggleWrapper>
              <label onChange={() => {}}>
                <input
                  defaultValue="on"
                  name="dummy"
                  type="checkbox"
                  className="bubble"
                  checked={text?.rule_status === "ACTIVE"}
                />
              </label>
            </ToggleWrapper>
          </Popconfirm>
        );
      },
    },
    {
      title: "Scheduled",
      width: 130,
      render: (text) => {
        if (!text?.next_execution_time) {
          return "-";
        }

        return (
          <span>
            {moment(new Date(parseInt(text?.next_execution_time) * 1000))
              .tz(
                contextVar?.data?.timezone?.[selectedMarketplace] ||
                  "US/Central"
              )
              .format("MMM DD, YYYY")}
          </span>
        );
      },
    },

    {
      title: "Clone",
      width: 100,
      render: (text) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedRow(text);
            setIsCloneStatus(true);
            switch (text?.rule_type) {
              case "Budget Management":
                setSelectedRow({
                  ...text?.sub_rule_list?.[0],
                  budget_date_range: JSON.parse(
                    text?.sub_rule_list?.[0]?.budget_date_range
                  ),
                  campaign_list: JSON.parse(
                    text?.sub_rule_list?.[0]?.campaign_list
                  ),
                  criteria: JSON.parse(text?.sub_rule_list?.[0]?.criteria),
                  recurrence: JSON.parse(text?.sub_rule_list?.[0]?.recurrence),
                });
                setBudgetManagementView(true);
                break;
              case "Search Term Management":
                setSearchTermManagementView(true);
                break;
              case "Bid Management":
                setBidManagementView(true);
                break;
              case "bid":
                setBidManagementView(true);
                break;
              case "budget":
                setSelectedRow({
                  ...text?.sub_rule_list?.[0],
                  budget_date_range: JSON.parse(
                    text?.sub_rule_list?.[0]?.budget_date_range
                  ),
                  campaign_list: JSON.parse(
                    text?.sub_rule_list?.[0]?.campaign_list
                  ),
                  criteria: JSON.parse(text?.sub_rule_list?.[0]?.criteria),
                  recurrence: JSON.parse(text?.sub_rule_list?.[0]?.recurrence),
                });
                setBudgetManagementView(true);
                break;
              case "search_term":
                setSearchTermManagementView(true);
                break;

              default:
                break;
            }
          }}
        >
          <i className="fas fa-copy text-dark fs-3" />
        </span>
      ),
    },
    {
      title: "Action Log",
      width: 130,
      render: (text) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedActionLog({
              ...text,
              type_action: "master",
            });

            setActionLogView(true);
          }}
        >
          <i className="fas fa-history text-dark fs-3" />
        </span>
      ),
    },
    {
      title: "Action",
      width: 100,
      render: (text) => (
        <div className="d-flex flex-shrink-0 justify-content-center">
          <button
            onClick={() => {
              setSelectedRow(text);
              switch (text?.rule_type) {
                case "Budget Management":
                  setSelectedRow({
                    ...text?.sub_rule_list?.[0],
                    budget_date_range: JSON.parse(
                      text?.sub_rule_list?.[0]?.budget_date_range
                    ),
                    campaign_list: JSON.parse(
                      text?.sub_rule_list?.[0]?.campaign_list
                    ),
                    criteria: JSON.parse(text?.sub_rule_list?.[0]?.criteria),
                    recurrence: JSON.parse(
                      text?.sub_rule_list?.[0]?.recurrence
                    ),
                  });
                  setBudgetManagementView(true);
                  break;
                case "Search Term Management":
                  setSearchTermManagementView(true);
                  break;
                case "Bid Management":
                  setBidManagementView(true);
                  break;
                case "bid":
                  setBidManagementView(true);
                  break;
                case "budget":
                  setSelectedRow({
                    ...text?.sub_rule_list?.[0],
                    budget_date_range: JSON.parse(
                      text?.sub_rule_list?.[0]?.budget_date_range
                    ),
                    campaign_list: JSON.parse(
                      text?.sub_rule_list?.[0]?.campaign_list
                    ),
                    criteria: JSON.parse(text?.sub_rule_list?.[0]?.criteria),
                    recurrence: JSON.parse(
                      text?.sub_rule_list?.[0]?.recurrence
                    ),
                  });
                  setBudgetManagementView(true);
                  break;
                case "search_term":
                  setSearchTermManagementView(true);
                  break;

                default:
                  break;
              }
            }}
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3"
          >
            <i className="ki-outline ki-eye fs-2 text-primary" />
          </button>
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedRow(text);
              setIsUpdateStatus(true);
              switch (text?.rule_type) {
                case "Budget Management":
                  setSelectedRow({
                    ...text?.sub_rule_list?.[0],
                    budget_date_range: JSON.parse(
                      text?.sub_rule_list?.[0]?.budget_date_range
                    ),
                    campaign_list: JSON.parse(
                      text?.sub_rule_list?.[0]?.campaign_list
                    ),
                    criteria: JSON.parse(text?.sub_rule_list?.[0]?.criteria),
                    recurrence: JSON.parse(
                      text?.sub_rule_list?.[0]?.recurrence
                    ),
                  });
                  setBudgetManagementView(true);
                  break;
                case "Search Term Management":
                  setSearchTermManagementView(true);
                  break;
                case "Bid Management":
                  setBidManagementView(true);
                  break;
                case "bid":
                  setBidManagementView(true);
                  break;
                case "budget":
                  setSelectedRow({
                    ...text?.sub_rule_list?.[0],
                    budget_date_range: JSON.parse(
                      text?.sub_rule_list?.[0]?.budget_date_range
                    ),
                    campaign_list: JSON.parse(
                      text?.sub_rule_list?.[0]?.campaign_list
                    ),
                    criteria: JSON.parse(text?.sub_rule_list?.[0]?.criteria),
                    recurrence: JSON.parse(
                      text?.sub_rule_list?.[0]?.recurrence
                    ),
                  });
                  setBudgetManagementView(true);
                  break;
                case "search_term":
                  setSearchTermManagementView(true);
                  break;
                default:
                  break;
              }
            }}
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3"
          >
            <i className="ki-outline ki-pencil fs-2 text-primary" />
          </a>

          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this task?"
            onConfirm={() => {
              message.destroy();
              message.loading("Loading...", 0);
              if (text?.rule_type === "budget") {
                DeleteRuleAction(text?.ruleId);
              } else {
                DeleteRuleAction(text?.id);
              }

              setRuleList((prev) =>
                prev?.filter((d) => parseInt(d?.id) !== parseInt(text?.id))
              );
            }}
            okText="Yes"
            cancelText="No"
            placement="left"
          >
            <a
              href="#"
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            >
              <i className="ki-outline ki-trash fs-2 text-danger" />
            </a>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const columnsSub = [
    {
      title: "#",
      width: 58,
      render: (text) => {
        return <span>{text?.key || 1}</span>;
      },
    },
    {
      title: "Sub Rule Name",
      width: 280,
      render: (text) => (
        <span className=" fw-bolder text-dark">{text?.rule_name || "-"}</span>
      ),
    },
    {
      title: "Campaigns",
      width: 205,
      render: (text) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="two-line" style={{ wordBreak: "break-all" }}>
              {typeof text?.campaign_list === "object"
                ? text?.campaign_list?.[0]
                : Object.values(JSON.parse(text?.campaign_list || [])?.[0]) ||
                  "-"}
            </div>
            <span
              className="ms-2 "
              onClick={() => {
                setSelectedCamp(
                  typeof text?.campaign_list === "object"
                    ? text?.campaign_list?.[0]
                    : JSON.parse(text?.campaign_list || [])
                );
                setCampaignView(true);
              }}
            >
              <i className="ki-outline ki-eye fs-3 text-dark" />
            </span>
          </div>
        );
      },
    },
    {
      title: "Status",
      width: 70,
      render: (text) => {
        return (
          <Popconfirm
            placement="left"
            title={`Are you sure you want to ${
              text?.rule_status === "ACTIVE" ? "disable" : "enable"
            } rule?`}
            onConfirm={() => {
              message.destroy();
              message.loading("Updating...", 0);

              UpdateSubRuleStatusAction(text?.id, {
                rule_status:
                  text?.rule_status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
              });
              setRuleList((prev) => {
                return prev.map((item) => {
                  if (item.id === text?.id) {
                    return {
                      ...item,
                      rule_status:
                        text?.rule_status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
                    };
                  }
                  return item;
                });
              });
            }}
            okText="Yes"
            cancelText="No"
          >
            <ToggleWrapper>
              <label onChange={() => {}}>
                <input
                  defaultValue="on"
                  name="dummy"
                  type="checkbox"
                  className="bubble"
                  checked={text?.rule_status === "ACTIVE"}
                />
              </label>
            </ToggleWrapper>
          </Popconfirm>
        );
      },
    },
    {
      title: "Scheduled",
      width: 165,
      render: (text) => {
        if (!text?.next_execution_time) {
          return "-";
        }
        return (
          <span>
            {moment(new Date(parseInt(text?.next_execution_time) * 1000))
              .tz(
                contextVar?.data?.timezone?.[selectedMarketplace] ||
                  "US/Central"
              )
              .format("MMM DD, YYYY")}
          </span>
        );
      },
    },

    {
      title: "Action Log",
      width: 95,
      render: (text) => (
        <span
          className
          onClick={() => {
            setSelectedActionLog({
              ...text,
              type_action: "",
            });
            // message.destroy();
            // message.loading("loading...", 0);
            // GetAppliedRuleAction("master", text?.id);
            setActionLogView(true);
          }}
        >
          <i className="fas fa-history text-dark fs-3" />
        </span>
      ),
    },
    {
      title: "Action",
      align: "left",
      width: 125,
      render: (text) => (
        <div className="d-flex flex-shrink-0 justify-content-center">
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this task?"
            onConfirm={() => {
              message.destroy();
              message.loading("Loading...", 0);

              DeleteRuleAction(text?.id, "sub");
              setRuleList((prev) =>
                prev?.map((d) => ({
                  ...d,
                  sub_rule_list: d?.sub_rule_list?.filter(
                    (t) => parseInt(t?.id) !== parseInt(text?.id)
                  ),
                }))
              );
            }}
            okText="Yes"
            cancelText="No"
            placement="left"
          >
            <a
              href="#"
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            >
              <i className="ki-outline ki-trash fs-2 text-danger" />
            </a>
          </Popconfirm>
        </div>
      ),
    },
  ];
  // const [expandedRows, setExpandedRows] = useState([]);

  // const handleRowClick = (id) => {
  //   const currentExpandedRows = [...expandedRows];
  //   const isRowExpanded = currentExpandedRows.includes(id);

  //   if (isRowExpanded) {
  //     setExpandedRows(currentExpandedRows.filter((rowId) => rowId !== id));
  //   } else {
  //     setExpandedRows([...currentExpandedRows, id]);
  //   }
  // };
  // const data = [
  //   { id: 1, name: "John Doe", email: "john@example.com" },
  //   { id: 2, name: "Jane Doe", email: "jane@example.com" },
  //   // Add more data as needed
  // ];

  return (
    <Wrapper>
      <div className="d-flex flex-column flex-column-fluid">
        {/*begin::Content*/}
        <div id="kt_app_content" className="app-content flex-column-fluid">
          {/*begin::Content container*/}
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className>
              {/*begin::Row*/}
              <div className="row gy-5 g-xl-5">
                {/*begin::Col*/}
                <div className="col-xxl-12">
                  {/*begin::Tables Widget 9*/}
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    {/*begin::Header*/}
                    <div className="card-header border-bottom-dashed">
                      <h3 className="card-title" />
                      <div className="card-toolbar">
                        <div className="d-flex flex-stack flex-wrap gap-4">
                          <div className="position-relative mr-4">
                            {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}
                            <span className="svg-icon svg-icon-2 position-absolute top-50 translate-middle-y ms-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <rect
                                  opacity="0.5"
                                  x="17.0365"
                                  y="15.1223"
                                  width="8.15546"
                                  height={2}
                                  rx={1}
                                  transform="rotate(45 17.0365 15.1223)"
                                  fill="black"
                                />
                                <path
                                  d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                            <input
                              type="text"
                              data-kt-table-widget-4="search"
                              className="form-control form-control-solid text-dark  w-250px fs-7 ps-12 all-search"
                              name="all_search"
                              placeholder="Search by Rule name"
                              onChange={(e) => {
                                setFilters({
                                  ...filters,
                                  rule_name: e?.target.value,
                                });
                                setRuleListLoading(true);
                                setRuleList([]);
                                GetRuleManagementListAction({
                                  page: page,
                                  perPage: pageSize,
                                  ...filters,
                                  rule_name: e?.target.value,
                                  marketplace_id: selectedMarketplace,
                                });
                              }}
                              value={filters?.rule_name || null}
                            />
                          </div>
                          <div className="position-relative mr-4">
                            <Select
                              size="large"
                              placeholder="Select Rule Type"
                              className="w-200px"
                              allowClear
                              onChange={(e) => {
                                setFilters({ ...filters, rule_type: e });
                                setRuleListLoading(true);
                                setRuleList([]);
                                GetRuleManagementListAction({
                                  page: page,
                                  perPage: pageSize,
                                  ...filters,
                                  rule_type: e,
                                  marketplace_id: selectedMarketplace,
                                });
                              }}
                              value={filters?.rule_type || null}
                              options={[
                                { label: "Bid Management", value: "bid" },
                                {
                                  label: "Search Term Management",
                                  value: "search_term",
                                },
                                { label: "Budget Management", value: "budget" },
                              ]}
                            />
                          </div>
                          <div className="position-relative mr-4">
                            <Select
                              size="large"
                              placeholder="Select Status"
                              className="w-200px "
                              allowClear
                              onChange={(e) => {
                                setFilters({ ...filters, rule_status: e });
                                setRuleListLoading(true);
                                setRuleList([]);
                                GetRuleManagementListAction({
                                  page: page,
                                  perPage: pageSize,
                                  ...filters,
                                  rule_status: e,
                                  marketplace_id: selectedMarketplace,
                                });
                              }}
                              value={filters?.rule_status || null}
                              options={[
                                { label: "Active", value: "ACTIVE" },
                                { label: "Inactive", value: "INACTIVE" },
                              ]}
                            />
                          </div>
                          <div className="position-relative d-grid">
                            <Select
                              size="large"
                              placeholder="Select Marketplace"
                              loading={marketplaceLoading}
                              value={selectedMarketplace || null}
                              onChange={(e) => {
                                setSelectedMarketplace(e);
                                setRuleListLoading(true);
                                setRuleList([]);
                                GetRuleManagementListAction({
                                  page: page,
                                  perPage: pageSize,
                                  ...filters,

                                  marketplace_id: e,
                                });
                              }}
                              style={{
                                width: "200px",
                              }}
                              options={marketplaceList?.map((d) => ({
                                label: d?.name,
                                value: d?.id,
                              }))}
                              className="fs-7"
                            />
                          </div>
                          {/* <a href="#" class="btn  fs-7 btn-primary fw-bold" data-bs-toggle="modal" data-bs-target="#kt_modal_add_rule">Add New Rule</a> */}
                          {/*begin::Menu*/}
                          <Dropdown
                            overlay={menu}
                            trigger={["click"]}
                            placement="bottomLeft"

                            // overlayClassName="menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 py-4 menu-state-bg-light-primary fw-semibold w-225px"
                          >
                            <button className="btn btn-dark fs-7 fw-bold show menu-dropdown">
                              Add New Rule
                            </button>
                          </Dropdown>
                          {/*end::Menu*/}
                        </div>
                      </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body py-2 pt-0">
                      <div className="table-responsive">
                        {ruleListLoading ? (
                          <TableLoading
                            id="test-table"
                            columns={[1, 2, 3, 4, 5]}
                            checkBoxVal={true}
                            actions={[1, 2]}
                          />
                        ) : ruleList?.length === 0 ? (
                          <NoData />
                        ) : (
                          <Table
                            dataSource={ruleList}
                            columns={columns}
                            rowKey="id"
                            pagination={false}
                            scroll={{ x: 1400 }}
                            expandable={{
                              rowExpandable: (record) =>
                                record.rule_type !== "budget",

                              expandedRowRender: (record) => {
                                return (
                                  <Table
                                    className="p-5"
                                    dataSource={record?.sub_rule_list?.map(
                                      (d, i) => ({ ...d, key: i + 1 })
                                    )}
                                    columns={columnsSub}
                                    rowKey="key"
                                    pagination={false}
                                    scroll={{ x: 900 }}
                                  />
                                );
                              },
                            }}
                          />
                        )}
                      </div>
                      <Pagination
                        loading={ruleListLoading || ruleList?.length === 0}
                        pageSize={pageSize}
                        page={page}
                        totalPage={totalPage}
                        onPerPage={onPerPage}
                        onPageNo={onPageNo}
                      />
                    </div>
                    {/*begin::Body*/}
                  </div>
                  {/*end::Tables Widget 9*/}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Row*/}
            </div>
          </div>
          {/*end::Content container*/}
        </div>
        {/*end::Content*/}
      </div>
      {campaignView && (
        <CampaignsView
          selectedCamp={selectedCamp}
          visible={campaignView}
          handleCancel={() => setCampaignView(false)}
        />
      )}
      {actionLogView && (
        <ActionLog
          visible={actionLogView}
          selectedActionLog={selectedActionLog}
          handleCancel={() => setActionLogView(false)}
          timezone={
            contextVar?.data?.timezone?.[selectedMarketplace] || "US/Central"
          }
          {...props}
        />
      )}
      <Modal
        title=""
        maskClosable={false}
        open={createANewCampaigns}
        onOk={() => setCreateANewCampaigns(false)}
        onCancel={() => setCreateANewCampaigns(false)}
      >
        <p>Go to Campaign Management to create New Campaign</p>
      </Modal>

      {bidManagementView && (
        <BidManagement
          visible={bidManagementView}
          allCampaignList={allCampaignList}
          dropdownList={dropdownList}
          dropdownLoading={dropdownLoading}
          selectedRow={selectedRow}
          allCampaignListLoading={allCampaignListLoading}
          isCloneStatus={isCloneStatus}
          isUpdateStatus={isUpdateStatus}
          selectedMarketplace={selectedMarketplace}
          handleCancel={() => {
            setSelectedRow({});
            setBidManagementView(false);
            setIsCloneStatus(false);
            setIsUpdateStatus(false);
          }}
          SIGN={SIGN}
          getList={() => {
            setRuleListLoading(true);
            setRuleList([]);
            GetRuleManagementListAction({
              page: page,
              perPage: pageSize,
              ...filters,
              marketplace_id: selectedMarketplace,
            });
          }}
          {...props}
        />
      )}
      {searchTermManagementView && (
        <SearchTermManagement
          visible={searchTermManagementView}
          handleCancel={() => {
            setSelectedRow({});
            setSearchTermManagementView(false);
            setIsCloneStatus(false);
            setIsUpdateStatus(false);
          }}
          setCreateANewCampaigns={setCreateANewCampaigns}
          selectedRow={selectedRow}
          createANewCampaigns={createANewCampaigns}
          selectedMarketplace={selectedMarketplace}
          allCampaignList={allCampaignList}
          allCampaignListLoading={allCampaignListLoading}
          dropdownList={dropdownList}
          isCloneStatus={isCloneStatus}
          dropdownLoading={dropdownLoading}
          isUpdateStatus={isUpdateStatus}
          getList={() => {
            setRuleListLoading(true);
            setRuleList([]);
            GetRuleManagementListAction({
              page: page,
              perPage: pageSize,
              ...filters,
              marketplace_id: selectedMarketplace,
            });
          }}
          {...props}
        />
      )}
      {budgetManagementView && (
        <BudgetManagement
          visible={budgetManagementView}
          allCampaignList={allCampaignList}
          allCampaignListLoading={allCampaignListLoading}
          dropdownList={dropdownList}
          isCloneStatus={isCloneStatus}
          dropdownLoading={dropdownLoading}
          selectedRow={selectedRow}
          isUpdateStatus={isUpdateStatus}
          selectedMarketplace={selectedMarketplace}
          handleCancel={() => {
            setSelectedRow({});
            setIsCloneStatus(false);
            setIsUpdateStatus(false);

            setBudgetManagementView(false);
          }}
          getList={() => {
            setRuleListLoading(true);
            setRuleList([]);
            GetRuleManagementListAction({
              page: page,
              perPage: pageSize,
              ...filters,
              marketplace_id: selectedMarketplace,
            });
          }}
          {...props}
        />
      )}
    </Wrapper>
  );
}
